<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="titlePage" :menu="menu" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="auto" class="align-center">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="12" md="4">
              <v-text-field
                filled
                solo
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" block color="#2ca01c" dark>
                    <v-icon>mdi-plus-thick</v-icon>
                    Nova Transação</v-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    style="text-transform: none"
                    width="8rem"
                    text
                    to="/finance/transaction/create?type=debit"
                  >
                    <span>Débito Avista ou Recorrente</span>
                  </v-list-item>
                  <v-list-item
                    style="text-transform: none"
                    width="8rem"
                    text
                    to="/finance/transaction/create?type=credit"
                  >
                    <span>Crédito Avista ou Recorrente</span>
                  </v-list-item>
                  <v-list-item
                    width="8rem"
                    text
                    to="/finance/movement/instalments?type=credit"
                    style="text-transform: none"
                  >
                    <span>Crédito Parcelado</span>
                  </v-list-item>
                  <v-list-item
                    width="8rem"
                    text
                    to="/finance/movement/instalments?type=debit"
                    style="text-transform: none"
                  >
                    <span>Débito Parcelado</span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="movements"
          :search="search"
          no-data-text="Sem dados no momento"
          no-results-text="Sem dados para sua busca"
          :header-props="{ sortByText: 'Ordenar por' }"
          loading-text="Carregando dados"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span>R$ {{ formatPrice(item.amount, item.type) }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ dateFormat(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <span>{{ dateFormat(item.dueDate) }}</span>
          </template>
          <template v-slot:[`item.number`]="{ item }">
            <span>{{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-switch
              small
              v-model="item.paid"
              disabled
              :label="item.paid ? 'Sim' : 'Não'"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" :fullscreen="fullScreen" width="1000">
        <v-card elevation="0">
          <v-toolbar color="primary" dark flat style="padding-right: 10px !important">
            <v-toolbar-title class="overline">Transação Financeira</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon @click="fullScreen ? (fullScreen = false) : (fullScreen = true)">{{
                setIconScreen()
              }}</v-icon>
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <br />
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="4" class="mt-n3">
                    <v-autocomplete
                      outlined
                      :items="accounts"
                      v-model="accountId"
                      label="Conta"
                      item-text="name"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" sm="3" class="mt-n3">
                    <v-select
                      outlined
                      :items="[
                        { id: 'C', label: 'Receita' },
                        { id: 'D', label: 'Despesa' },
                      ]"
                      v-model="type"
                      item-value="id"
                      item-text="label"
                      label="Tipo"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3" class="mt-n9">
                    <span>Valor</span>
                    <money class="money" v-model="amount" v-bind="money"></money>
                  </v-col>

                  <v-col cols="6" sm="2" class="mr-n8">
                    <v-switch :value="paid" v-model="paid" label="Pago"></v-switch>
                  </v-col>

                  <v-col cols="3">
                    <v-select
                      outlined
                      :items="plans"
                      item-text="label"
                      item-value="id"
                      v-model="paymentPlan"
                      label="Plano de Pagamento"
                    ></v-select>
                  </v-col>
                  <v-col cols="3" v-if="paymentPlan === 'R' || paymentPlan === 'I'">
                    <v-select
                      outlined
                      :items="[
                        { id: 'W', label: 'Semanal' },
                        { id: 'M', label: 'Mensal' },
                        { id: 'Y', label: 'Anual' },
                      ]"
                      item-text="label"
                      item-value="id"
                      v-model="frequencyType"
                      label="Frquencia"
                    ></v-select>
                  </v-col>

                  <v-col cols="3">
                    <v-autocomplete
                      outlined
                      :items="paymentMethods"
                      v-model="paymentMethodId"
                      label="Forma de Pagamento"
                      item-text="title"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      type="date"
                      outlined
                      v-model="referenceDate"
                      label="Data Ocorrência"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      type="date"
                      outlined
                      v-model="dueDate"
                      label="Data Vencimento"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="mt-n8">
                    <v-text-field
                      outlined
                      v-model="title"
                      label="Descrição"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" v-if="type === 'C'" class="mt-n8">
                    <v-autocomplete
                      outlined
                      :items="customers"
                      v-model="customerId"
                      label="Recebido de"
                      item-text="person.corporateName"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" v-if="type === 'D'" class="mt-n8">
                    <v-autocomplete
                      outlined
                      :items="suppliers"
                      v-model="supplierId"
                      label="Pagp Para"
                      item-text="person.corporateName"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-autocomplete
                      outlined
                      :items="categories"
                      v-model="categoryId"
                      label="Classificação"
                      item-text="name"
                      item-value="id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog()">
                    Fechar
                  </v-btn>
                  <v-btn :loading="loadingAction" color="success" @click="validate()">
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import moment from "moment";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  title: "FinancialMovement",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    titlePage: "Transações Financeiras",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Contas Financeiras",
            to: "/finance/accounts",
            permission: "gerentor.finance.accounts.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Classificações Financeiras",
            to: "/finance/categories",
            permission: "gerentor.finance.categories.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Métodos de Pagamento",
            to: "/finance/payment-methods",
            permission: "gerentor.finance.payment_methods.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Extrato de Contas",
            to: "/finance/account-reports",
            permission: "gerentor.finance.payment_methods.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Transações",
        disabled: true,
        href: "/finance/transaction/instalment",
      },
    ],
    valid: true,
    accounts: [],
    movements: [],
    paymentMethods: [],
    categories: [],
    members: [],
    customers: [],
    suppliers: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    title: "",
    referenceDate: null,
    amount: "",
    categoryId: "",
    accountId: null,
    paymentMethodId: "",
    memberId: null,
    title: "",
    type: "C",
    dueDate: new Date(),
    supplierId: null,
    customerId: null,
    paid: false,
    paymentPlan: "S",
    frequencyType: null,
    openDialog: false,
    fullScreen: false,
    plans: [
      {
        id: "S",
        label: "Avista",
      },
      {
        id: "R",
        label: "Recorrente",
      },
      {
        id: "I",
        label: "Parcelado",
      },
    ],
    headers: [
      {
        text: "Data",
        align: "start",
        value: "createdAt",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Descrição",
        align: "start",
        value: "title",
        filtering: true,
      },
      {
        text: "Conta",
        align: "start",
        value: "account.identifier",
        filtering: true,
      },
      {
        text: "Tipo",
        align: "start",
        value: "type",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "amount",
        filtering: true,
      },
      { text: "Pago", value: "paid" },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateFormat(referenceDate) {
      return moment(referenceDate).format("DD/MM");
    },
    getItems() {
      this.loading = true;
      http.get("finance/transactions?sort=createdAt,DESC").then((data) => {
        this.movements = data.data;
        this.loading = false;
      });
    },
    getPayments() {
      this.loading = true;
      http.get("finance/payment-methods").then((data) => {
        this.paymentMethods = data.data;
        this.loading = false;
      });
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
    },
    getSuppliers() {
      this.loading = true;
      http.get("gerentor/suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
    },
    getCustomers() {
      this.loading = true;
      http.get("gerentor/customers").then((data) => {
        this.customers = data.data;
        this.loading = false;
      });
    },
    getCategories() {
      this.loading = true;
      http.get("finance/categories").then((data) => {
        this.categories = data.data;
        this.loading = false;
      });
    },

    registerOrUpdate() {
      this.loadingAction = true;
      let payload = {
        referenceDate: this.referenceDate,
        paid: this.paid,
        paidDate: this.paid ? this.referenceDate : null,
        datetime: new Date(),
        dueDate: this.dueDate,
        paymentPlan: this.paymentPlan,
        type: this.type,
        paymentMethodId: this.paymentMethodId,
        title: this.title,
        accountId: this.accountId,
        categoryId: this.categoryId,
        amount: this.amount,
        customerId: this.customerId,
        supplierId: this.supplierId,
        frequencyType: this.frequencyType,
      };

      if (this.id) {
        http.put(`finance/transactions/${this.id}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("finance/transactions", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
            this.getItems();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.id = "";
      this.amount = "";
      this.openDialog = false;
    },
    editItem(item) {
      this.title = item.title;
      this.id = item.id;
      this.amount = item.amount;
      this.categoryId = item.categoryId;
      this.paymentMethodId = item.paymentMethodId;
      this.referenceDate = item.referenceDate;
      this.type = item.type;
      this.accountId = item.accountId;
      this.supplierId = item.supplierId;
      this.customerId = item.customerId;
      this.paymentPlan = item.paymentPlan;
      this.dueDate = item.dueDate;
      this.paid = item.paid;
      this.openDialog = true;
    },
  },
  watch: {
    paid() {
      if (this.paymentPlan === "S") this.paid = true;
    },
  },
  mounted() {
    this.getItems();
    this.getAccounts();
    this.getCategories();
    this.getPayments();
    this.getSuppliers();
    this.getCustomers();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 57px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
